.star-rating {
    display: inline-flex;
    cursor: pointer;
    margin: 5px;
    width: 100%;
  }
  
  .star.fa {
    font-size: 24px;
    color: #d3d3d3;
  }
  
  .star.fas {
    color: #ffc107;
  }
  
  .star.fa:hover,
  .star.fa:hover ~ .star.fa {
    color: #ffc107;
  }